<script>
    import { Router, Link, Route } from "svelte-routing";
    import { fetch_get, fetch_patch, fetch_post } from "../../helpers/fetch_utils.js";
    import {
        Alert,
        Card,
        CardBody,
        CardImg,
        CardTitle,
        Col,
        Container,
        Form,
        FormGroup,
        Input,
        Label,
        Row,
    } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../common/Breadcrumb.svelte";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing/src/history.js";
    import Select from "svelte-select/src/Select.svelte";
    import generos from "../../common/data/generos.js";
    import moment from "moment";

    export let id;
    let actualizar = id != null;
    let alumno = {};
    let tarjetas = [];
    let fileinput;
    let registrando = false;
    let opcionales = [
        "dni",
        "fechaDeNacimiento",
        "foto",
        "telefono",
    ];

    $: isRegistrar = (actualizar
        ? Object.keys(alumno).filter((v) =>
              v == "password" ? false : ("" + alumno[v]).trim() === "" && !opcionales.includes(v)
          ).length == 0
        : Object.keys(alumno).filter((v) => (!alumno[v] || ("" + alumno[v]).trim() === "") && !opcionales.includes(v))
              .length == 0) && !registrando;

    async function onSubmit(e) {
        registrando = true;
        let alumnoUpload = {
            ...alumno,
        };
        alumnoUpload.genero = alumnoUpload.genero.value;
        if (actualizar) {
            const data = await fetch_patch(
                `//api.codeasi.info/api/v1/alumno/`+id,
                alumnoUpload
            );
            if (data != null) {
                success();
            }
        } else {
            const data = await fetch_post(
                `//api.codeasi.info/api/v1/alumno`,
                alumnoUpload
            );
            if (data != null) {
                success();
            }
        }
        registrando = false;
    }

    let visibleSuccess = false;
    function success() {
        visibleSuccess = true;
        setTimeout(() => {
            visibleSuccess = false;
        }, 3000);
        if (!actualizar) {
            fileinput.value = '';
            let v = Object.assign({}, alumno);
            for( let k in v ) v[k] = "";
            alumno = v;
            fetchTarjetas();
        }
    }

    const onFileSelected = (e) => {
        let image = e.target.files[0];
        resizeImage(image);
    };

    function resizeImage(file) {
        if (
            window.File &&
            window.FileReader &&
            window.FileList &&
            window.Blob
        ) {
            if (file) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    var img = document.createElement("img");
                    img.onload = function () {                    
                        var canvas = document.createElement("canvas");
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);

                        var MAX_WIDTH = 400;
                        var MAX_HEIGHT = 400;
                        var width = img.width;
                        var height = img.height;

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);

                        let dataurl = canvas.toDataURL(file.type);
                        alumno.photo = dataurl;
                        //document.getElementById("output").src = dataurl;
                    };
                    img.src = e.target.result;
                };
            }
        } else {
            alert("The File APIs are not fully supported in this browser.");
        }
    }

    async function fetchTarjetas(){
        tarjetas = [];
        const res = await fetch_get(
            "//api.codeasi.info/api/v1/tarjeta/lista_registrar_alumno",
            {}
        );
        tarjetas = res;
    }

    onMount(async () => {
        await fetchTarjetas();
        if (actualizar) {
            const data = await fetch_get(
                `//api.codeasi.info/api/v1/alumno/`+id,
            );
            let dataTemp = {};
            dataTemp.nombre = data.user.nombre;
            dataTemp.apellidoPaterno = data.user.apellidoPaterno;
            dataTemp.apellidoMaterno = data.user.apellidoMaterno;
            dataTemp.usuario = data.user.usuario;
            dataTemp.dni = data.user.dni;
            dataTemp.telefono = data.user.telefono;
            if(data.tarjeta){
                tarjetas.push(data.tarjeta);
                dataTemp.tarjeta = data.tarjeta;
            }
            dataTemp.genero = generos.valueToEstado(data.user.genero);
            if(data.user.photo){
                dataTemp.photo = data.user.photo.path;
            }
            if(data.user.fechaDeNacimiento){
                dataTemp.fechaDeNacimiento = moment(data.user.fechaDeNacimiento).utc(false).format('yyyy-MM-DD');
            }
            alumno = dataTemp;
        }
    });
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs
            title="Alumnos"
            breadcrumbItem={actualizar ? "Actualizar" : "Registrar"}
        />
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle class="h4 mb-4"
                            >{actualizar
                                ? "Actualizar Alumno"
                                : "Registrar Alumno"}</CardTitle
                        >

                        <form on:submit|preventDefault={onSubmit}>
                            <Row>
                                <Col md={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Nombre</Label>
                                        <Input
                                            bind:value={alumno.nombre}
                                            type="text"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Apellido Paterno</Label>
                                        <Input
                                            bind:value={alumno.apellidoPaterno}
                                            type="text"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Apellido Materno</Label>
                                        <Input
                                            bind:value={alumno.apellidoMaterno}
                                            type="text"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Código de acceso</Label>
                                        <Input
                                            bind:value={alumno.usuario}
                                            type="text"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Contraseña</Label>
                                        <Input
                                            bind:value={alumno.password}
                                            type="password"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <div class="mb-3 select2-container">
                                        <Label>Tarjeta</Label>
                                        <Select
                                            items={tarjetas}
                                            placeholder="Seleccionar..."
                                            bind:value={alumno.tarjeta}
                                            labelIdentifier="codigo" 
                                            optionIdentifier="id"
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <FormGroup class="mb-3">
                                        <Label>DNI</Label>
                                        <Input
                                            bind:value={alumno.dni}
                                            type="text"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <div class="mb-3 select2-container">
                                        <Label>Genero</Label>
                                        <Select
                                            items={generos.generos}
                                            placeholder="Seleccionar..."
                                            bind:value={alumno.genero}
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Fecha de nacimiento</Label>
                                        <Input
                                            bind:value={alumno.fechaDeNacimiento}
                                            type="date"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Telefono</Label>
                                        <Input
                                            bind:value={alumno.telefono}
                                            type="text"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Foto</Label>
                                        <input
                                            accept="image/png, image/jpeg"
                                            on:change={(e) => onFileSelected(e)}
                                            bind:this={fileinput}
                                            type="file"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    {#if alumno.photo}
                                        <CardImg
                                            src={alumno.photo}
                                            alt="Skote"
                                            class="rounded avatar-lg mb-3"
                                            style="object-fit: scale-down;"
                                        />
                                    {/if}
                                </Col>
                            </Row>

                            <div>
                                {#if visibleSuccess}
                                    <div class="card bg-success text-white">
                                        <div class="card-header">Se {actualizar ? "Actualizo" : "Registro"} correctamente</div>
                                    </div>
                                {/if}
                                {#if actualizar}
                                    <button
                                        type="submit"
                                        class="btn btn-secondary w-md"
                                        on:click={() => navigate("/alumno")}
                                    >
                                        Atras
                                    </button>
                                {/if}
                                <button
                                    type="submit"
                                    class="btn btn-primary w-md"
                                    disabled={!isRegistrar}
                                >
                                    {actualizar ? "Actualizar" : "Registrar"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
