<script>
    import { Router, Link, Route } from "svelte-routing";
    import { fetch_get, fetch_post } from "../../helpers/fetch_utils.js";
    import {
        Alert,
        Card,
        CardBody,
        CardTitle,
        Col,
        Container,
        Form,
        FormGroup,
        Input,
        Label,
        Row,
    } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../common/Breadcrumb.svelte";
    import estadosDeAsistencia from "../../common/data/estado-asistencia";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing/src/history.js";
    import Select from "svelte-select";
    import moment from "moment";
    import estadoAsistencia from "../../common/data/estado-asistencia";

    export let id;
    let actualizar = id != null;
    let asistencia = {};
    let alumnos = [];
    let aulas = [];

    $: isRegistrar =
        Object.keys(asistencia).filter(
            (v) => ("" + asistencia[v]).trim() === ""
        ).length == 0;
    $: isSelectAlumno = alumnos.length > 0 && asistencia.fecha != "";

    let timeoutSuccess;
    async function onSubmit(e) {
        if (timeoutSuccess) {
            visibleSuccess = false;
            clearTimeout(timeoutSuccess);
            timeoutSuccess = undefined;
        }
        const data = await fetch_post(
            `//api.codeasi.info/api/v1/asistencia`,
            {
                matricula: asistencia.alumno.id,
                fecha: asistencia.fecha,
                tipo: "MANUAL",
                estado: asistencia.estado.value,
            }
        );
        if (data != null) {
            success();
        }
    }

    let visibleSuccess = false;
    function success() {
        visibleSuccess = true;
        timeoutSuccess = setTimeout(() => {
            timeoutSuccess = undefined;
            visibleSuccess = false;
        }, 3000);
        /*if (!actualizar) {
            asistencia = {};
        }*/
    }

    let firstLoadActualizar = false;
    async function selectAula(e) {
        if (!actualizar || firstLoadActualizar) {
            asistencia.alumno = undefined;
            alumnos = asistencia.aula.matriculas.map((v) => {
                v["label"] =
                    v.alumno.user.nombre +
                    " " +
                    v.alumno.user.apellidoPaterno +
                    " " +
                    v.alumno.user.apellidoMaterno;
                return v;
            });
        }
        firstLoadActualizar = true;
    }

    async function selectAlumno(e) {
        asistencia.estado = undefined;
        const asistenciaData = await fetch_post(
            "//api.codeasi.info/api/v1/asistencia/byFechaMatricula",
            {
                matricula: asistencia.alumno.id,
                fecha: asistencia.fecha,
            }
        );
        if (asistenciaData.asistencia) {
            asistencia.estado = estadosDeAsistencia.valueToEstado(
                asistenciaData.asistencia.estado
            );
        }
    }

    onMount(async () => {
        const aulasData = await fetch_get(
            "//api.codeasi.info/api/v1/aula/withAlumnos",
            {}
        );
        aulas = aulasData.map((v) => {
            v["label"] =
                v.nivel.nombre + " " + v.grado.nombre + "" + v.seccion.nombre;
            return v;
        });

        if (actualizar) {
            const data = await fetch_get(
                `//api.codeasi.info/api/v1/asistencia/` + id
            );
            let dataAsistencia = data;
            //console.log(dataAsistencia.matricula);
            alumnos = aulas
                .filter(
                    (aula) => aula.id == dataAsistencia.matricula.aula.id
                )[0]
                .matriculas.map((v) => {
                    v["label"] =
                        v.alumno.user.nombre +
                        " " +
                        v.alumno.user.apellidoPaterno +
                        " " +
                        v.alumno.user.apellidoMaterno;
                    return v;
                });
            asistencia = {
                aula: aulas.filter(
                    (aula) => aula.id == dataAsistencia.matricula.aula.id
                )[0],
                fecha: moment(dataAsistencia.fecha)
                    .utc(false)
                    .format("yyyy-MM-DD"),
                estado: estadosDeAsistencia.valueToEstado(
                    dataAsistencia.estado
                ),
                alumno: alumnos.filter(
                    (alumno) =>
                        alumno.alumno.id == dataAsistencia.matricula.alumno.id
                )[0],
            };
            console.log(asistencia);
        }
    });
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs
            title="Asistencia"
            breadcrumbItem={actualizar ? "Actualizar" : "Gestionar"}
        />
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle class="h4 mb-4"
                            >{actualizar
                                ? "Gestionar Asistencia"
                                : "Registrar Asistencia"}</CardTitle
                        >

                        <form on:submit|preventDefault={onSubmit}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup class="mb-3">
                                        <Label>Fecha</Label>
                                        <Input
                                            bind:value={asistencia.fecha}
                                            type="date"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <div class="mb-3 select2-container">
                                        <Label>Aula</Label>
                                        <Select
                                            items={aulas}
                                            placeholder="Seleccionar..."
                                            bind:value={asistencia.aula}
                                            optionIdentifier="id"
                                            on:select={selectAula}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div class="mb-3 select2-container">
                                <Label>Alumno</Label>
                                <Select
                                    items={alumnos}
                                    placeholder="Seleccionar..."
                                    bind:value={asistencia.alumno}
                                    isDisabled={!isSelectAlumno}
                                    on:select={selectAlumno}
                                    optionIdentifier="id"
                                />
                            </div>

                            <div class="mb-3 select2-container">
                                <Label>Asistencia</Label>
                                <Select
                                    items={estadosDeAsistencia.estadosDeAsistencia}
                                    placeholder="Seleccionar..."
                                    bind:value={asistencia.estado}
                                    isDisabled={asistencia.alumno ==
                                        undefined || asistencia.alumno == ""}
                                />
                            </div>

                            <div>
                                {#if visibleSuccess}
                                    <div class="card bg-success text-white">
                                        <div class="card-header">
                                            Se {actualizar
                                                ? "Actualizo"
                                                : "Registro"} correctamente
                                        </div>
                                    </div>
                                {/if}
                                {#if actualizar}
                                    <button
                                        type="submit"
                                        class="btn btn-secondary w-md"
                                        on:click={() => navigate("/asistencia")}
                                    >
                                        Atras
                                    </button>
                                {/if}
                                <button
                                    type="submit"
                                    class="btn btn-primary w-md"
                                    disabled={!isRegistrar}
                                >
                                    Actualizar
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
