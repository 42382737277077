<script>
    import { Router, Link, Route } from "svelte-routing";
    import { fetch_get, fetch_patch, fetch_post } from "../../helpers/fetch_utils.js";
    import {
    Alert,
        Card,
        CardBody,
        CardTitle,
        Col,
        Container,
        Form,
        FormGroup,
        Input,
        Label,
        Row,
    } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../common/Breadcrumb.svelte";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing/src/history.js";
    import Select from "svelte-select";

    export let id;
    let actualizar = id != null;
    let matricula = {};
    let alumnos = [];
    let aulas = [];

    $: isRegistrar =
        Object.keys(matricula).filter((v) => !matricula[v] || ("" + matricula[v]).trim() === "")
            .length == 0;

    async function onSubmit(e) {
        if (actualizar) {
            const data = await fetch_patch(
                `//api.codeasi.info/api/v1/matricula/`+id,
                {
                    aula: matricula.aula.id,
                    alumno: matricula.alumno.id,
                }
            );
            if (data != null) {
                success();
            }
        } else {
            const data = await fetch_post(
                `//api.codeasi.info/api/v1/matricula`,
                {
                    aula: matricula.aula.id,
                    alumno: matricula.alumno.id,
                }
            );
            if (data != null) {
                success();
            }
        }
    }

    let visibleSuccess = false;
    function success() {
        visibleSuccess = true;
        setTimeout(() => {
            visibleSuccess = false;
        }, 3000);
        if (!actualizar) {
            matricula = {};
        }
    }

    onMount(async () => {
        const res = await fetch_get(
            "//api.codeasi.info/api/v1/alumno",
            {}
        );
        alumnos = res.map(v=>{
            v["nombre"] = v.user.nombre + " " + v.user.apellidoPaterno + " " + v.user.apellidoMaterno;
            return v;
        });

        const resAulas = await fetch_get(
            "//api.codeasi.info/api/v1/aula",
            {}
        );
        aulas = resAulas.map(v=>{
            v["label"] = v.nivel.nombre + " " + v.grado.nombre + "" + v.seccion.nombre;
            return v;
        });
        if (actualizar) {
            const data = await fetch_get(
                `//api.codeasi.info/api/v1/matricula/`+id,
            );
            let dataMatricula = {};
            dataMatricula.alumno = data.alumno;
            dataMatricula.aula = data.aula;
            dataMatricula.alumno.nombre = data.alumno.user.nombre + " " + data.alumno.user.apellidoPaterno + " " + data.alumno.user.apellidoMaterno;
            dataMatricula.aula.label = data.aula.nivel.nombre + " " + data.aula.grado.nombre + "" + data.aula.seccion.nombre;
            matricula = dataMatricula;
        }
    });
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs
            title="Matriculas"
            breadcrumbItem={actualizar ? "Actualizar" : "Registrar"}
        />
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle class="h4 mb-4"
                            >{actualizar
                                ? "Actualizar Matricula"
                                : "Registrar Matricula"}</CardTitle
                        >

                        <form on:submit|preventDefault={onSubmit}>
                            <Row>
                                <Col md={6}>
                                    <div class="mb-3 select2-container">
                                        <Label>Alumno</Label>
                                        <Select 
                                            items={alumnos} 
                                            placeholder="Seleccionar..." 
                                            labelIdentifier="nombre" 
                                            optionIdentifier="id"
                                            bind:value={matricula.alumno}/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div class="mb-3 select2-container">
                                        <Label>Aula</Label>
                                        <Select 
                                        items={aulas} 
                                        placeholder="Seleccionar..." 
                                        optionIdentifier="id"
                                        bind:value={matricula.aula}/>
                                    </div>
                                </Col>
                            </Row>

                            <div>
                                {#if visibleSuccess}
                                    <div class="card bg-success text-white">
                                        <div class="card-header">Se {actualizar ? "Actualizo" : "Registro"} correctamente</div>
                                    </div>
                                {/if}
                                {#if actualizar}
                                    <button
                                        type="submit"
                                        class="btn btn-secondary w-md"
                                        on:click={() => navigate("/matricula")}
                                    >
                                        Atras
                                    </button>
                                {/if}
                                <button
                                    type="submit"
                                    class="btn btn-primary w-md"
                                    disabled={!isRegistrar}
                                >
                                    {actualizar ? "Actualizar" : "Registrar"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
