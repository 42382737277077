<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { Datatable } from "svelte-simple-datatables";
    import { Card, CardBody, Col, Container, Row, Table } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../common/Breadcrumb.svelte";
    import generos from "../../common/data/generos";
    import { fetch_get, fetch_post } from "../../helpers/fetch_utils";

    let data = [];

    const settings = {
        labels: {
            search: "Buscar...", // search input placeholer
            filter: "Filtrar", // filter inputs placeholder
            noRows: "No se ha encontrado ningun alumno",
            info: 'Mostrando pagina {start} de {end} de {rows} Alumnos',
            previous: "Anterior",
            next: "Siguiente",
        },
        sortable: true,
        pagination: true,
        rowsPerPage: 10,
        columnFilter: true,
    };

    const handleEditar = async (item) => {
        console.log(item);
		navigate('/alumno-actualizar/'+item.id);
	};

    onMount(async () => {
        const res = await fetch_get(
            "//api.codeasi.info/api/v1/alumno",
            {}
        );
        console.log(res);
        data = res.map(v=>{
            v.user.genero = generos.valueToLabel(v.user.genero);
            return v;
        });
    });
    let rows;
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs title="Alumnos" breadcrumbItem="Listar" />

        <Row>
            <Col sm={12}>
                <Card>
                    <CardBody>
                        <div class="h4 card-title">Lista de Alumnos</div>
                        {#if data.length>0}
                            <Datatable {settings} {data} bind:dataRows={rows}>
                                <thead>
                                    <th data-key="(row) => row.user.usuario">Código</th>
                                    <th data-key="(row) => row.user.apellidoMaterno + ' ' + row.user.apellidoPaterno + ' ' + row.user.nombre">Nombre</th>
                                    <th data-key="genero">Genero</th>
                                    <th data-key="genero">Telefono</th>
                                    <th>Acciones</th>
                                </thead>
                                <tbody>
                                    {#if rows}
                                        {#each $rows as item}
                                            <tr>
                                                <th scope="row">{item.user.usuario}</th>
                                                <td>{item.user.apellidoPaterno} {item.user.apellidoMaterno} {item.user.nombre}</td>
                                                <td>{item.user.genero}</td>
                                                <td>{item.user.telefono}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-sm"
                                                        on:click={handleEditar(item)}
                                                        >Editar</button
                                                    >
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-sm"
                                                        >Eliminar</button
                                                    >
                                                </td>
                                            </tr>
                                        {/each}
                                    {/if}
                                </tbody>
                            </Datatable>
                        {/if}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
