<script>
    import { Router, Link, Route } from "svelte-routing";
    import { fetch_get, fetch_patch, fetch_post } from "../../helpers/fetch_utils.js";
    import {
        Alert,
        Card,
        CardBody,
        CardTitle,
        Col,
        Container,
        Form,
        FormGroup,
        Input,
        Label,
        Row,
    } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../common/Breadcrumb.svelte";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing/src/history.js";
    import moment from "moment";

    export let id;
    let actualizar = id != null;
    let temporada = {};

    $: isRegistrar =
        Object.keys(temporada).filter((v) => ("" + temporada[v]).trim() === "")
            .length == 0;

    async function onSubmit(e) {
        visibleSuccess = false;
        if (actualizar) {
            const data = await fetch_patch(
                `//api.codeasi.info/api/v1/temporada/`+id,
                temporada
            );
            if (data != null) {
                success();
            }
        } else {
            const data = await fetch_post(
                `//api.codeasi.info/api/v1/temporada`,
                temporada
            );
            if (data != null) {
                success();
            }
        }
    }

    let visibleSuccess = false;
    function success() {
        visibleSuccess = true;
        setTimeout(() => {
            visibleSuccess = false;
        }, 3000);
        if (!actualizar) {
            temporada = {};
        }
    }

    onMount(async () => {
        if (actualizar) {
            const data = await fetch_get(
                `//api.codeasi.info/api/v1/temporada/`+id,
            );
            temporada = data;
            temporada.fechaInicio = moment(temporada.fechaInicio).utc(false).format('yyyy-MM-DD');
            temporada.fechaFin = moment(temporada.fechaFin).utc(false).format('yyyy-MM-DD');
        }
    });
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs
            title="Temporadas"
            breadcrumbItem={actualizar ? "Actualizar" : "Registrar"}
        />
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle class="h4 mb-4"
                            >{actualizar
                                ? "Actualizar Temporada"
                                : "Registrar Temporada"}</CardTitle
                        >

                        <form on:submit|preventDefault={onSubmit}>
                            <Row>
                                <Col lg={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Nombre</Label>
                                        <Input
                                            bind:value={temporada.nombre}
                                            type="text"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Fecha de Inicio</Label>
                                        <Input
                                            bind:value={temporada.fechaInicio}
                                            type="date"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={4}>
                                    <FormGroup class="mb-3">
                                        <Label>Fecha Fin</Label>
                                        <Input
                                            bind:value={temporada.fechaFin}
                                            type="date"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <div>
                                {#if visibleSuccess}
                                    <div class="card bg-success text-white">
                                        <div class="card-header">Se {actualizar ? "Actualizo" : "Registro"} correctamente</div>
                                    </div>
                                {/if}
                                {#if actualizar}
                                    <button
                                        type="submit"
                                        class="btn btn-secondary w-md"
                                        on:click={() => navigate("/temporada")}
                                    >
                                        Atras
                                    </button>
                                {/if}
                                <button
                                    type="submit"
                                    class="btn btn-primary w-md"
                                    disabled={!isRegistrar}
                                >
                                    {actualizar ? "Actualizar" : "Registrar"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
