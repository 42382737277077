<script>
    import jsPDFInvoiceTemplate, { OutputType, jsPDF } from "../../helpers/pdf_utils";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { Datatable } from "svelte-simple-datatables";
    import {
        Button,
        Card,
        CardBody,
        CardTitle,
        Col,
        Container,
        Input,
        Label,
        Row,
        Table,
    } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../common/Breadcrumb.svelte";
    import { fetch_delete, fetch_get, fetch_post } from "../../helpers/fetch_utils";
    import estadosDeAsistencia from "../../common/data/estado-asistencia";
    import estadoAsistencia from "../../common/data/estado-asistencia";
    import moment from "moment";
    import Select from "svelte-select/src/Select.svelte";

    let data = [];

    let aulas = [];
    let filtro = {};

    const settings = {
        labels: {
            search: "Buscar...", // search input placeholer
            filter: "Filtrar", // filter inputs placeholder
            noRows: "No se ha encontrado ninguna asistencia",
            info: "Mostrando pagina {start} de {end} de {rows} Asistencias",
            previous: "Anterior",
            next: "Siguiente",
        },
        sortable: true,
        pagination: true,
        rowsPerPage: 10,
        columnFilter: true,
    };

    const handleEditar = async (item) => {
        navigate("/asistencia-actualizar/" + item.id);
    };

    const handleEliminar = async (item) => {
        const res = await fetch_delete(
            "//api.codeasi.info/api/v1/asistencia/"+item.id,
        );
        if(res==1){
            for(let i=0;i<data.length;i++){
                if(data[i].id==item.id){
                    data.splice(i, 1);
                }
            }
        }
        data = data;
    };

    async function changeFecha(){
        if(filtro.aula){
            fetchAsistencia();
        }
    }

    async function selectAula(){
        if(filtro.fecha){
            fetchAsistencia();
        }
    }

    async function fetchAsistencia(){
        data = [];
        const res = await fetch_post(
            "//api.codeasi.info/api/v1/asistencia/filter",
            {
                fecha: filtro.fecha,
                aula: filtro.aula.id,
            }
        );
        data = res.map((v) => {
            v.estado = estadoAsistencia.valueToLabel(v.estado);
            v.fecha = moment(v.fecha).utc(false).format("DD/MM/yyyy");
            return v;
        })
    }

    let time = 0;
    async function clickPDF() {
        let start = new Date();
        var props = {
            outputType: OutputType.Save,
            returnJsPDFDocObject: true,
            fileName: "Invoice 2021",
            orientationLandscape: false,
            compress: true,
            logo: {
                src: "assets/images/users/avatar-2.png",
                type: "PNG", //optional, when src= data:uri (nodejs case)
                width: 25, //aspect ratio = width/height
                height: 25,
                margin: {
                    top: 0, //negative or positive num, from the current position
                    left: 0, //negative or positive num, from the current position
                },
            },
            /*stamp: {
                inAllPages: true, //by default = false, just in the last page
                src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
                type: "JPG", //optional, when src= data:uri (nodejs case)
                width: 20, //aspect ratio = width/height
                height: 20,
                margin: {
                    top: 0, //negative or positive num, from the current position
                    left: 0, //negative or positive num, from the current position
                },
            },*/
            business: {
                name: "Tercer Cielo",
                address: "Jr,Junin # 1992, Trujillo, Peru",
                phone: "+51 920 835 916",
                email: "leoncito5.3@hotmail.com",
                //email_1: false,
                //website: "https://www.facebook.com/WWW.IEPTERCERCIELO",
            },
            /*contact: {
                label: "Invoice issued for:",
                name: "Client Name",
                address: "Albania, Tirane, Astir",
                phone: "(+355) 069 22 22 222",
                email: "client@website.al",
                otherInfo: "www.website.al",
            },*/
            invoice: {
                label: "Reporte de asistencias #",
                num: 1,
                invGenDate: "Fecha: "+filtro.fecha,
                invDate: "Aula: "+filtro.aula.label,
                headerBorder: true,
                tableBodyBorder: true,
                header: [
                    {
                        title: "#",
                        style: {
                            width: 10,
                        },
                    },
                    {
                        title: "Fecha",
                        style: {
                            width: 30,
                        },
                    },
                    {
                        title: "Nombre",
                        style: {
                            width: 80,
                        },
                    },
                    { title: "Estado" },
                    { title: "Tipo" },
                    { title: "Tiempo" },
                ],
                table: Array.from(data, (item, index) => [
                    index+1,
                    filtro.fecha,
                    item.matricula.alumno.user.nombre + ' ' + item.matricula.alumno.user.apellidoPaterno + ' ' + item.matricula.alumno.user.apellidoMaterno,
                    item.estado,
                    item.tipo,
                    item.timems + " ms",
                ]),
                /*additionalRows: [
                    {
                        col1: "Total:",
                        col2: "145,250.50",
                        col3: "ALL",
                        style: {
                            fontSize: 14, //optional, default 12
                        },
                    },
                    {
                        col1: "VAT:",
                        col2: "20",
                        col3: "%",
                        style: {
                            fontSize: 10, //optional, default 12
                        },
                    },
                    {
                        col1: "SubTotal:",
                        col2: "116,199.90",
                        col3: "ALL",
                        style: {
                            fontSize: 10, //optional, default 12
                        },
                    },
                ],*/
                //invDescLabel: "Invoice Note",
                //invDesc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.",
            },
            footer: {
                text: "El tiempo promedio de toma de asistencia fue de "+Math.round(data.reduce((a,b)=>a+b.timems,0)/1000)+" segundos ("+data.reduce((a,b)=>a+b.timems,0)+" milisegundos).",
            },
            pageEnable: true,
            pageLabel: "Pagina ",
        };
        jsPDFInvoiceTemplate( props );
        time = new Date().getTime() - start.getTime();
        start = new Date();
    }

    onMount(async () => {
        const aulasData = await fetch_get(
            "//api.codeasi.info/api/v1/aula",
            {}
        );
        aulas = aulasData.map((v) => {
            v["label"] =
                v.nivel.nombre + " " + v.grado.nombre + "" + v.seccion.nombre;
            return v;
        });
    });
    let rows;
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs title="Asistencia" breadcrumbItem="Listar" />

        <Row>
            <Col sm={12}>
                <Card>
                    <CardBody>
                        <div class="h4 card-title">Lista de Asistencia</div>

                        <Row>
                            <Col sm={4}>
                                <div class="mb-3">
                                    <Label>Fecha</Label>
                                    <Input
                                        bind:value={filtro.fecha}
                                        on:change={changeFecha}
                                        type="date"
                                        class="form-control"
                                    />
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div class="mb-3 select2-container" style="z-index: 2000; position: relative;">
                                    <Label>Aula</Label>
                                    <Select
                                        style="" 
                                        items={aulas}
                                        placeholder="Seleccionar..."
                                        bind:value={filtro.aula}
                                        optionIdentifier="id"
                                        on:select={selectAula}
                                    />
                                </div>
                            </Col>
                            <Col sm={1} class="mt-4">
                                {#if filtro.aula && filtro.fecha}
                                    <Button
                                        type="button"
                                        class="btn btn-success"
                                        on:click={fetchAsistencia}>
                                        <i class="mdi mdi-cloud-refresh font-size-16 align-middle"/>
                                    </Button>
                                {/if} 
                            </Col>
                            <Col sm={3} class="mt-3">
                                {#if data.length>0}
                                    <div class="d-grid">
                                        <small>
                                        Tiempo en generar el reporte: {Math.round(time/1000)} segundos ({time} milisegundos)
                                    </small>
                                        <Button
                                            type="button"
                                            class="btn btn-danger"
                                            on:click={clickPDF}>
                                            <i
                                                class="bx bxs-file-pdf font-size-16 align-middle me-2"
                                            />
                                            Reporte en PDF
                                        </Button>
                                    </div>
                                {/if} 
                            </Col>
                        </Row>

                        {#if data.length > 0}
                            <Datatable {settings} {data} bind:dataRows={rows}>
                                <thead>
                                    <th
                                        data-key="(row) => row.matricula.alumno.user.nombre + ' ' + row.matricula.alumno.user.apellidoPaterno + ' ' + row.matricula.alumno.user.apellidoMaterno"
                                        >Nombre Completo</th
                                    >
                                    <th
                                        data-key="(row) => row.matricula.nivel + ' ' + row.matricula.grado + row.matricula.seccion"
                                        >Aula</th
                                    >
                                    <th data-key="fecha">Fecha</th>
                                    <th data-key="estado">Estado</th>
                                    <th data-key="tipo">Tipo</th>
                                    <th data-key="timems">Tiempo</th>
                                    <th>Acciones</th>
                                </thead>
                                <tbody>
                                    {#if rows}
                                        {#each $rows as item}
                                            <tr>
                                                <td
                                                    >{item.matricula.alumno.user
                                                        .nombre}
                                                    {item.matricula.alumno.user
                                                        .apellidoPaterno}
                                                    {item.matricula.alumno.user
                                                        .apellidoMaterno}</td
                                                >
                                                <td
                                                    >{item.matricula.aula.nivel
                                                        .nombre}
                                                    {item.matricula.aula.grado
                                                        .nombre}{item.matricula
                                                        .aula.seccion
                                                        .nombre}</td
                                                >
                                                <td>{item.fecha}</td>
                                                <td>{item.estado}</td>
                                                <td>{item.tipo}</td>
                                                <td>{item.timems} ms</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-sm"
                                                        on:click={handleEditar(
                                                            item
                                                        )}>Editar</button
                                                    >
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-sm"
                                                        on:click={handleEliminar(
                                                            item
                                                        )}>Eliminar</button
                                                    >
                                                </td>
                                            </tr>
                                        {/each}
                                    {/if}
                                </tbody>
                            </Datatable>
                        {/if}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
