// Authentication related pages
import Login from "../pages/Authentication/Login.svelte";
import Register from "../pages/Authentication/Register.svelte";

// Dashboard
import Dashboard from "../pages/Dashboard/index.svelte";

//Alumnos
import Alumnos from "../pages/Alumno/index.svelte";
import AlumnosRegistrar from "../pages/Alumno/registrar.svelte";

//Temporadas
import Temporada from "../pages/Temporada/index.svelte";
import TemporadaRegistrar from "../pages/Temporada/registrar.svelte";

//Matriculas
import Matricula from "../pages/Matricula/index.svelte";
import MatriculaRegistrar from "../pages/Matricula/registrar.svelte";

//Asistencia
import Asistencia from "../pages/Asistencia/index.svelte";
import AsistenciaGestionar from "../pages/Asistencia/gestionar.svelte";

//Tarjeta
import Tarjeta from "../pages/Tarjeta/index.svelte";

//Aula
import Aula from "../pages/Aula/index.svelte";
import AulaRegistrar from "../pages/Aula/registrar.svelte";
import Nivel from "../pages/Aula/Nivel/index.svelte";
import NivelRegistrar from "../pages/Aula/Nivel/registrar.svelte";
import Grado from "../pages/Aula/Grado/index.svelte";
import GradoRegistrar from "../pages/Aula/Grado/registrar.svelte";
import Seccion from "../pages/Aula/Seccion/index.svelte";
import SeccionRegistrar from "../pages/Aula/Seccion/registrar.svelte";

const authProtectedRoutes = [
  { path: "/", component: Dashboard },
  { path: "/index", component: Dashboard },
  { path: "/dashboard", component: Dashboard },

  { path: "/alumno", component: Alumnos },
  { path: "/alumno-registrar", component: AlumnosRegistrar },
  { path: "/alumno-actualizar/:id", component: AlumnosRegistrar },

  { path: "/temporada", component: Temporada },
  { path: "/temporada-registrar", component: TemporadaRegistrar },
  { path: "/temporada-actualizar/:id", component: TemporadaRegistrar },

  { path: "/matricula", component: Matricula },
  { path: "/matricula-registrar", component: MatriculaRegistrar },
  { path: "/matricula-actualizar/:id", component: MatriculaRegistrar },

  { path: "/asistencia", component: Asistencia },
  { path: "/asistencia-gestionar", component: AsistenciaGestionar },
  { path: "/asistencia-actualizar/:id", component: AsistenciaGestionar },

  { path: "/tarjeta", component: Tarjeta },

  { path: "/aula", component: Aula },
  { path: "/aula-registrar", component: AulaRegistrar },
  { path: "/aula-actualizar/:id", component: AulaRegistrar },
  { path: "/nivel", component: Nivel },
  { path: "/nivel-registrar", component: NivelRegistrar },
  { path: "/nivel-actualizar/:id", component: NivelRegistrar },
  { path: "/grado", component: Grado },
  { path: "/grado-registrar", component: GradoRegistrar },
  { path: "/grado-actualizar/:id", component: GradoRegistrar },
  { path: "/seccion", component: Seccion },
  { path: "/seccion-registrar", component: SeccionRegistrar },
  { path: "/seccion-actualizar/:id", component: SeccionRegistrar },

];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
];

const MOCK_DATA = {
  authProtectedRoutes,
  publicRoutes,
};

export default MOCK_DATA;