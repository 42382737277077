<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { Datatable } from "svelte-simple-datatables";
    import { Card, CardBody, Col, Container, Row, Table } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../common/Breadcrumb.svelte";
    import moment from "moment";
    import { fetch_get, fetch_post } from "../../helpers/fetch_utils";

    let data = [];

    const settings = {
        labels: {
            search: "Buscar...", // search input placeholer
            filter: "Filtrar", // filter inputs placeholder
            noRows: "No se ha encontrado ninguna tarjeta",
            info: 'Mostrando pagina {start} de {end} de {rows} Tarjetas',
            previous: "Anterior",
            next: "Siguiente",
        },
        sortable: true,
        pagination: true,
        rowsPerPage: 10,
        columnFilter: true,
    };

    const handleEditar = async (item) => {
        console.log(item);
		navigate('/tarjeta-actualizar/'+item.id);
	};

    onMount(async () => {
        const res = await fetch_get(
            "//api.codeasi.info/api/v1/tarjeta",
            {}
        );
        console.log(res);
        data = res;
    });
    let rows;
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs title="Tarjetas" breadcrumbItem="Listar" />

        <Row>
            <Col sm={12}>
                <Card>
                    <CardBody>
                        <div class="h4 card-title">Lista de Tarjetas</div>
                        {#if data.length>0}
                            <Datatable {settings} {data} bind:dataRows={rows}>
                                <thead>
                                    <th data-key="codigo">Código</th>
                                    <th data-key="codigo">Alumno</th>
                                    <th>Fecha de creacion</th>
                                    <!--th>Acciones</th-->
                                </thead>
                                <tbody>
                                    {#if rows}
                                        {#each $rows as item}
                                            <tr>
                                                <td>{item.codigo}</td>
                                                <td>{item.alumno?.user?.nombre ?? "-"} {item.alumno?.user?.apellidoPaterno ?? "-"} {item.alumno?.user?.apellidoMaterno ?? "-"}</td>
                                                <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        {/each}
                                    {/if}
                                </tbody>
                            </Datatable>
                        {/if}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
