<script>
    import { Router, Link, Route } from "svelte-routing";
    import { fetch_get, fetch_patch, fetch_post } from "../../helpers/fetch_utils.js";
    import {
        Card,
        CardBody,
        CardTitle,
        Col,
        Container,
        FormGroup,
        Input,
        Label,
        Row,
    } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../common/Breadcrumb.svelte";
    import { onDestroy, onMount } from "svelte";
    import { navigate } from "svelte-routing/src/history.js";
    import Select from "svelte-select/src/Select.svelte";

    export let id;
    let actualizar = id != null;
    let aula = {};
    let niveles = [];
    let grados = [];
    let secciones = [];

    $: isRegistrar =
        Object.keys(aula).filter((v) => !aula[v] || ("" + aula[v]).trim() === "")
            .length == 0;

    async function onSubmit(e) {
        visibleSuccess = false;
        if (actualizar) {
            const data = await fetch_patch(
                `//api.codeasi.info/api/v1/aula/`+id,
                {
                    nivel: aula.nivel.id,
                    grado: aula.grado.id,
                    seccion: aula.seccion.id,
                    timeInicio: aula.timeInicio,
                    timeTardanza: aula.timeTardanza,
                    timeFalta: aula.timeFalta,
                    timeSalida: aula.timeSalida,
                }
            );
            if (data != null) {
                success();
            }
        } else {
            const data = await fetch_post(
                `//api.codeasi.info/api/v1/aula`,
                {
                    nivel: aula.nivel.id,
                    grado: aula.grado.id,
                    seccion: aula.seccion.id,
                    timeInicio: aula.timeInicio,
                    timeTardanza: aula.timeTardanza,
                    timeFalta: aula.timeFalta,
                    timeSalida: aula.timeSalida,
                }
            );
            if (data != null) {
                success();
            }
        }
    }

    let visibleSuccess = false;
    function success() {
        visibleSuccess = true;
        setTimeout(() => {
            visibleSuccess = false;
        }, 3000);
        if (!actualizar) {
            aula = {};
        }
    }

    let firstLoadActualizar = false;
    async function selectNivel(e){
        if(!actualizar || firstLoadActualizar){
            aula.grado = undefined;
            aula.seccion = undefined;
            grados = aula.nivel.grados;
        }
        firstLoadActualizar = true;
    }

    async function selectGrado(e){
        aula.seccion = undefined;
        secciones = aula.grado.secciones;
    }

    onMount(async () => {
        const nivelesRes = await fetch_get(
            "//api.codeasi.info/api/v1/nivel",
            {}
        );
        niveles = nivelesRes;
        if (actualizar) {
            let data = await fetch_get(
                `//api.codeasi.info/api/v1/aula/`+id,
            );
            grados = niveles.filter(v=>data.nivel.id==v.id)[0].grados;
            aula = {
                nivel: data.nivel,
                grado: data.grado,
                seccion: data.seccion,
                timeInicio: data.timeInicio,
                timeTardanza: data.timeTardanza,
                timeFalta: data.timeFalta,
                timeSalida: data.timeSalida,
            };
            console.log(aula);
        }
        console.log("xDDDDDDD");
    });
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs
            title="Aulas"
            breadcrumbItem={actualizar ? "Actualizar" : "Registrar"}
        />
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle class="h4 mb-4"
                            >{actualizar
                                ? "Actualizar Aula"
                                : "Registrar Aula"}</CardTitle
                        >

                        <form on:submit|preventDefault={onSubmit}>
                            <Row>
                                <Col lg={4}>
                                    <div class="mb-3 select2-container">
                                        <Label>Nivel</Label>
                                        <Select items={niveles} placeholder="Seleccionar..." bind:value={aula.nivel} labelIdentifier="nombre" optionIdentifier="id" on:select={selectNivel}/>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div class="mb-3 select2-container">
                                        <Label>Grado</Label>
                                        <Select items={grados} placeholder="Seleccionar..." bind:value={aula.grado} labelIdentifier="nombre" optionIdentifier="id" isDisabled={aula.nivel==undefined || aula.nivel==""} on:select={selectGrado}/>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div class="mb-3 select2-container">
                                        <Label>Seccion</Label>
                                        <Select items={secciones} placeholder="Seleccionar..." bind:value={aula.seccion} labelIdentifier="nombre" optionIdentifier="id" isDisabled={aula.grado==undefined || aula.grado==""}/>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={3}>
                                    <div class="mb-3">
                                        <Label>Hora de entada</Label>
                                        <Input
                                            bind:value={aula.timeInicio}
                                            type="time"
                                            class="form-control"
                                        />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div class="mb-3">
                                        <Label>Hora de tardanza</Label>
                                        <Input
                                            bind:value={aula.timeTardanza}
                                            type="time"
                                            class="form-control"
                                        />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div class="mb-3">
                                        <Label>Hora de falta</Label>
                                        <Input
                                            bind:value={aula.timeFalta}
                                            type="time"
                                            class="form-control"
                                        />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div class="mb-3">
                                        <Label>Hora de salida</Label>
                                        <Input
                                            bind:value={aula.timeSalida}
                                            type="time"
                                            class="form-control"
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <div>
                                {#if visibleSuccess}
                                    <div class="card bg-success text-white">
                                        <div class="card-header">Se {actualizar ? "Actualizo" : "Registro"} correctamente</div>
                                    </div>
                                {/if}
                                {#if actualizar}
                                    <button
                                        type="submit"
                                        class="btn btn-secondary w-md"
                                        on:click={() => navigate("/aula")}
                                    >
                                        Atras
                                    </button>
                                {/if}
                                <button
                                    type="submit"
                                    class="btn btn-primary w-md"
                                    disabled={!isRegistrar}
                                >
                                    {actualizar ? "Actualizar" : "Registrar"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
