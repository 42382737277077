<script>
    import { Router, Link, Route } from "svelte-routing";
    import { fetch_get, fetch_patch, fetch_post } from "../../../helpers/fetch_utils.js";
    import {
        Alert,
        Card,
        CardBody,
        CardTitle,
        Col,
        Container,
        Form,
        FormGroup,
        Input,
        Label,
        Row,
    } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../../common/Breadcrumb.svelte";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing/src/history.js";
    import Select from "svelte-select/src/Select.svelte";

    export let id;
    let actualizar = id != null;
    let grado = {};
    let niveles;

    $: isRegistrar =
        Object.keys(grado).filter((v) => !grado[v] || ("" + grado[v]).trim() === "")
            .length == 0;

    async function onSubmit(e) {
        visibleSuccess = false;
        if (actualizar) {
            const data = await fetch_patch(
                `//api.codeasi.info/api/v1/grado/`+id,
                grado
            );
            if (data != null) {
                success();
            }
        } else {
            const data = await fetch_post(
                `//api.codeasi.info/api/v1/grado`,
                grado
            );
            if (data != null) {
                success();
            }
        }
    }

    let visibleSuccess = false;
    function success() {
        visibleSuccess = true;
        setTimeout(() => {
            visibleSuccess = false;
        }, 3000);
        if (!actualizar) {
            grado = {};
        }
    }

    onMount(async () => {
        const nivelesRes = await fetch_get(
            "//api.codeasi.info/api/v1/nivel",
            {}
        );
        niveles = nivelesRes;
        if (actualizar) {
            const data = await fetch_get(
                `//api.codeasi.info/api/v1/grado/`+id,
            );
            grado = data;
            console.log(grado);
        }
    });
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs
            title="Grados"
            breadcrumbItem={actualizar ? "Actualizar" : "Registrar"}
        />
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle class="h4 mb-4"
                            >{actualizar
                                ? "Actualizar Grado"
                                : "Registrar Grado"}</CardTitle
                        >

                        <form on:submit|preventDefault={onSubmit}>
                            <Row>
                                <Col lg={6}>
                                    <div class="mb-3 select2-container">
                                        <Label>Nivel</Label>
                                        <Select items={niveles} placeholder="Seleccionar..." bind:value={grado.nivel} labelIdentifier="nombre" optionIdentifier="id"/>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup class="mb-3">
                                        <Label>Nombre</Label>
                                        <Input
                                            bind:value={grado.nombre}
                                            type="text"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <div>
                                {#if visibleSuccess}
                                    <div class="card bg-success text-white">
                                        <div class="card-header">Se {actualizar ? "Actualizo" : "Registro"} correctamente</div>
                                    </div>
                                {/if}
                                {#if actualizar}
                                    <button
                                        type="submit"
                                        class="btn btn-secondary w-md"
                                        on:click={() => navigate("/grado")}
                                    >
                                        Atras
                                    </button>
                                {/if}
                                <button
                                    type="submit"
                                    class="btn btn-primary w-md"
                                    disabled={!isRegistrar}
                                >
                                    {actualizar ? "Actualizar" : "Registrar"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
