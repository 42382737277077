<script>
    import { Router, Link, Route } from "svelte-routing";
    import { fetch_get, fetch_patch, fetch_post } from "../../../helpers/fetch_utils.js";
    import {
        Alert,
        Card,
        CardBody,
        CardTitle,
        Col,
        Container,
        Form,
        FormGroup,
        Input,
        Label,
        Row,
    } from "sveltestrap";
    //Import Breadcrumb
    import Breadcrumbs from "../../../common/Breadcrumb.svelte";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing/src/history.js";

    export let id;
    let actualizar = id != null;
    let nivel = {};

    $: isRegistrar =
        Object.keys(nivel).filter((v) => ("" + nivel[v]).trim() === "")
            .length == 0;

    async function onSubmit(e) {
        visibleSuccess = false;
        if (actualizar) {
            const data = await fetch_patch(
                `//api.codeasi.info/api/v1/nivel/`+id,
                nivel
            );
            if (data != null) {
                success();
            }
        } else {
            const data = await fetch_post(
                `//api.codeasi.info/api/v1/nivel`,
                nivel
            );
            if (data != null) {
                success();
            }
        }
    }

    let visibleSuccess = false;
    function success() {
        visibleSuccess = true;
        setTimeout(() => {
            visibleSuccess = false;
        }, 3000);
        if (!actualizar) {
            nivel = {};
        }
    }

    onMount(async () => {
        if (actualizar) {
            const data = await fetch_get(
                `//api.codeasi.info/api/v1/nivel/`+id,
            );
            nivel = data;
        }
    });
</script>

<div class="page-content">
    <Container fluid>
        <Breadcrumbs
            title="Niveles"
            breadcrumbItem={actualizar ? "Actualizar" : "Registrar"}
        />
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle class="h4 mb-4"
                            >{actualizar
                                ? "Actualizar Nivel"
                                : "Registrar Nivel"}</CardTitle
                        >

                        <form on:submit|preventDefault={onSubmit}>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup class="mb-3">
                                        <Label>Nombre</Label>
                                        <Input
                                            bind:value={nivel.nombre}
                                            type="text"
                                            class="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <div>
                                {#if visibleSuccess}
                                    <div class="card bg-success text-white">
                                        <div class="card-header">Se {actualizar ? "Actualizo" : "Registro"} correctamente</div>
                                    </div>
                                {/if}
                                {#if actualizar}
                                    <button
                                        type="submit"
                                        class="btn btn-secondary w-md"
                                        on:click={() => navigate("/nivel")}
                                    >
                                        Atras
                                    </button>
                                {/if}
                                <button
                                    type="submit"
                                    class="btn btn-primary w-md"
                                    disabled={!isRegistrar}
                                >
                                    {actualizar ? "Actualizar" : "Registrar"}
                                </button>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
