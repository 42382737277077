<script>
  import {
    Container
  } from "sveltestrap";
  //Import Breadcrumb
  import Breadcrumbs from "../../common/Breadcrumb.svelte";

</script>

<div class="page-content">
  <Container fluid>
    <Breadcrumbs title="Principal" breadcrumbItem="Dashboard" />
    Dashboard
  </Container>
</div>